<template>
    <div class="faq--container">
        <iframe src="https://eyeinc.ru" width="335px" height="600px"></iframe>
    </div>
</template>

<script>
    export default {
        name: "SettingsFaqView"
    }
</script>

<style scoped lang="scss">
    .faq--container{
        width: 335px;
        height: 600px;
        outline: 0;
        border: 0;
        overflow: scroll;
        margin-left: auto;
        margin-right: auto;
        margin-top: 24px;
    }
</style>